<template>
    <!-- ============================ Page Title Start================================== -->
    <div class="page-title  bl-dark" >
        <div class="container">

        </div>
    </div>
    <!-- ============================ Page Title End ================================== -->

    <!-- ============================ Main Section Start ================================== -->
    <section class="gray-bg pt-4">
        <div class="container-fluid">
            <div class="row m-0">

                <account-sidebar activeMenu="bookmark_history" activeBlock="insight">
                </account-sidebar>

                <!-- Item Wrap Start -->
                <div class="col-xl-9 col-lg-8 col-md-12 col-sm-12">
                    <div class="row" >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <!-- Breadcrumbs -->
                            <div class="bredcrumb_wrap">
                                <nav aria-label="breadcrumb">
                                  <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Bookmark history</li>
                                  </ol>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <job-bookmark-history>
                            </job-bookmark-history>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </section>
    <!-- ============================ Main Section End ================================== -->
</template>

<script>
// @ is an alias to /src
import { useStore } from 'vuex';
import AccountSidebar from '@/components/account/AccountSidebar.vue';
import JobBookmarkHistory from '@/components/job/JobBookmarkHistory.vue';

export default {
  name: "BookmarkHistory",
  components:{
    AccountSidebar,
    JobBookmarkHistory
  },
  setup(){

      // get the global vuex store values
      const store = useStore();

      // use the dispatch function to call an action that calls a mutation
      store.dispatch("globals/executeSetNavMenu",true);
      store.dispatch("globals/executeSetFooterMenu",true);

  }


};
</script>

<style scoped>

.employer-brands ul li{
    list-style-type:none;
    display:inline;
}

.icon-size{
    font-size:2rem;
}

</style>
