<template>
    <!-- ============================ Main Section Start ================================== -->
    <!-- Single Wrap -->
    <div class="_dashboard_content">
        <div class="_dashboard_content_header">
            <div class="_dashboard__header_flex">
                <h4><i class="bi bi-megaphone mr-1"></i>Jobs you have bookmarked</h4>
            </div>
        </div>

        <div class="_dashboard_content_body p-0">
            <div class="_dashboard_list_group">

                <!-- Single List jobs -->
                <div class="_dash_singl_box" v-for="(job, index) in compFeaturedJobOffers" :key="job.id">
                    <div class="_dash_singl_thumbs">
                        <img src="https://via.placeholder.com/100x100" class="img-fluid" alt="">
                    </div>
                    <div class="_dash_singl_captions">
                        <h4 class="_jb_title">
                            <a href="javascript:void(0)" @click="updateJobDetails(job.id, index)" data-toggle="modal" data-target="#jobDetails">
                                {{ job.title }}
                            </a>
                            <span class="_dash_status approval" v-if="false">pending Approval</span>
                        </h4>
                        <ul class="_grouping_list">
                            <li><span><i class="bi bi-briefcase"></i>{{ job.username.substr(0,100) }}</span></li>
                            <li><span><i class="bi bi-globe"></i>{{ JSON.parse(job.job_location)['country'] }}, {{ JSON.parse(job.job_location)['city'] }}</span></li>
                            <li>
                                <span><i class="bi bi-credit-card-2-front"></i>
                                {{ job.currency == 'n'?'&#8358;':'' }}
                                {{ job.currency == '$'?'$':'' }}
                                {{ job.currency == '£'?'£':'' }}
                                {{ job.currency == 'c'?'&#8373;':'' }}
                                {{ job.currency == 'e'?'&#8364;':'' }}
                                <i class="bi bi-credit-card-2-front pr-1" v-if="job.salary_status == 'none' ||
                                    job.salary_status == 'request_salary' "></i>
                                {{ job.salary_status == "none" ||
                                   job.salary_status == "request_salary" ?
                                   'salary n/a ':job.salary.toLocaleString("en") + ' ' +  placeholderOptions[job.job_type]
                                }}
                            </span>
                            </li>
                            <li>
                                <span>
                                    <i class="bi bi-clock"></i>
                                    Expiring on {{ new Date(job.end_date).toLocaleDateString("en-US") }}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>


            </div>
        </div>
    </div>
    <!-- Single Wrap End -->
    <!-- ============================ Main Section End ================================== -->

    <base-modal mId="jobDetails"  mBg="bg-bage" mSize="lg"  >
         <template #header-left-slot>
             <div class="cursor-pointer" >
                 <span @click="gotoNextJob('prevs')" class="mr-2 mt-2 p-1 col-lg-1 col-4 btn btn-outline-secondary rounded small-font" v-if="isAuthenticated">
                     <i class="bi " :class="exceedMinJobList?' bi-stop-circle':'bi-chevron-double-left'"></i> prevs
                  </span>

                 <span @click="gotoNextJob('next')"  class="mr-2 mt-2 p-1 col-lg-1 col-4 btn btn-outline-secondary  rounded small-font"  v-if="isAuthenticated" >
                     next <i class="bi bi-chevron-double-right" :class="exceedMaxJobList?' bi-stop-circle':'bi-chevron-double-right'"></i>
                 </span>

                 <span class="mr-2 mt-2 col-lg-2 col-4 p-1 btn btn-secondary  rounded small-font" style="color:#333;" v-if="!isAuthenticated" >
                    <a :href="coreUrl.accountUrl+'login?platform=jobs'">
                         <i class="bi bi-lock"></i> Login to apply
                    </a>
                 </span>



             </div>
         </template>
         <template #header-right-slot>
             <span data-dismiss="modal" aria-label="Close">
                 <i class="bi bi-x-lg"></i>
             </span>
         </template>
         <template #default>

             <job-details :jobContent="currentJobDetails"
                          :key="currentKey"
                          :applicationHistory="myApplications"
                          :bookmarkHistory="myBookmarks"
                          @emit-update-job-history="updateJobHistory"
                          v-if="currentJobDetails.length > 0" >
             </job-details>

         </template>
    </base-modal>
</template>

<script>
// @ is an alias to /src
import { ref,inject,computed } from 'vue';
import { useStore } from 'vuex';
import JobDetails from '@/components/job/JobDetails.vue';

export default {
  name: "JobBookmarkHistory",
  components: {
      JobDetails
  },
  setup(){

      const axios = inject('axios');
      const coreUrl = inject('coreUrl');

      const store = useStore();

      // get the current status of client account
      const isAuthenticated = ref(store.getters["auth/getAuthAuthentication"].isAuthenticated);

      const contractType = {
          full_time:"bg-success",
          part_time:"bg-primary",
          consultancy:"bg-info",
          freelance:"bg-secondary"
      }

      const loadJobList = ref(false);
      const featuredJobOffers = ref([]);
      const myApplications = ref([]);
      const myBookmarks = ref([]);

      const params = { par_total: 10000 , order_type: 'desc' }
      // use axios to get the data
      axios.get(
          coreUrl.backendUrl+'get_simple_job_ad_list',
          {withCredentials: true , params: params }).then((response) =>{

          const result = response.data.data.job_ads;

          // use the dispatch function to call an action that calls a mutation
          // to set the data of for the job ad
          featuredJobOffers.value = result

          if(featuredJobOffers.value){
              loadJobList.value = true
          }

          myApplications.value = response.data.data.applications?JSON.parse(response.data.data.applications):[]
          myBookmarks.value = response.data.data.bookmarks?JSON.parse(response.data.data.bookmarks):[]


      }).catch((error)=>{
              console.log(error)
      })


      const compFeaturedJobOffers = computed(function(){

          let finalFeaturedJobOffers = []

          finalFeaturedJobOffers = featuredJobOffers.value.filter((item) => myBookmarks.value.includes(item.id) )

          return finalFeaturedJobOffers;

      })


      const currentJobDetails = ref([]);
      const currentJobIndex = ref(0);
      const currentKey = ref(0);
      function updateJobDetails(id, index){
          currentJobDetails.value = featuredJobOffers.value.filter((item) => item.id === id);
          currentJobIndex.value = index;
          currentKey.value = id;
      }

      const exceedMaxJobList = ref(false);
      const exceedMinJobList = ref(false);
      function gotoNextJob(direction){
          if(direction == 'next')
          {
            let newIndex = +currentJobIndex.value + 1;
            let jobListLength =  compFeaturedJobOffers.value.length;
             if( newIndex < jobListLength ) {
                 exceedMaxJobList.value = false;
                 exceedMinJobList.value = false;
                 let job_id = compFeaturedJobOffers.value[newIndex]['id']
                 currentJobDetails.value = featuredJobOffers.value.filter((item) => item.id === job_id);
                 currentJobIndex.value = newIndex;
                 currentKey.value = job_id;
             }else{
                 exceedMaxJobList.value = true;
             }
          }


          if(direction == 'prevs')
          {
            let newIndex = +currentJobIndex.value - 1;
            let jobListLength = -1;
             if( newIndex > jobListLength ) {
                 console.log(newIndex)
                 console.log(jobListLength)
                 exceedMaxJobList.value = false;
                 exceedMinJobList.value = false;
                 let job_id = compFeaturedJobOffers.value[newIndex]['id']
                 currentJobDetails.value = featuredJobOffers.value.filter((item) => item.id === job_id);
                 currentJobIndex.value = newIndex;
                 currentKey.value = job_id;
             }else{
                 exceedMinJobList.value = true;
             }
          }

      }

      function updateJobHistory(history, data){
          if(history == 'applications'){
              myApplications.value = data
          }

          if(history == 'bookmarks'){
              myBookmarks.value = data
          }

      }


      const placeholderOptions = {
          full_time:"salary per annum",
          part_time:"salary per annum",
          consultancy:"consultancy fee",
          freelance:"freelance fee",
          internship:"salary per annum",
          contract:"contract fee",
          volunteer:"volunteer fee"
      }



      return {
          coreUrl,
          contractType,
          isAuthenticated,
          loadJobList,
          featuredJobOffers,
          compFeaturedJobOffers,
          currentJobDetails,
          currentKey,
          gotoNextJob,
          exceedMaxJobList,
          exceedMinJobList,
          updateJobDetails,
          updateJobHistory,
          myApplications,
          myBookmarks,
          placeholderOptions
      }


  }
};
</script>

<style scoped>
.text-grey {
    color: #ccc;
}
</style>
